<template>
  <div>
    OK
  </div>
</template>

<script>
import axios from "axios";
export default {
  
  data() {
    return {
      is_loading: true,
      is_api:false,
      api_data:null,
      api_counter:0,
      form: {},
      mydata: null,
      main:null,
      search: "",
      page: 1,
      id_load: "",
      mycate:null,
      my_url: window.my_url,

      page_size:0,
      is_remove:[],

      isLogin:null,
      formSearch:{
         name:"",
          cate:""
      },
      language: window.my_header.language,
      

      
    };
  },

  mounted() {

   


   let p2=  axios
      .get(window.my_api + "api/bus/get-buss", {
        headers: window.my_header
      })
      .then((res) => {
        if (res.status == 200) {
          this.mydata  = this.main= res.data.data;
          
        } else {
          alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
        }
      });

      Promise.all([p2])
             .then( ()=>{
                this.RunAPI();
             });
  },
  methods: {
      
    RunAPI(){
        this.is_api=true;

        let id_data=[];
        for (var i = this.main.length - 1; i >= 0; i--) {
            id_data.push(this.main[i].name) ;
        }

        let p1=axios.get("http://apiservices.ebms.vn/api/routes/?apikey=P4iw3GVQ9kbYfWOR95ZcmnHT00jKKa4z")
          .then( (res)=>{
           
              if(res.status==200){
                  this.api_data=res.data;
              }else{
                alert("API không hoạt động. Vui lòng kiểm tra lại.");
                this.is_api=false;
              }
          });


        Promise.all([p1]).then(()=>{
            let array_process=[];
            for (var i = this.api_data.length - 1; i >= 0; i--) {
              
              if(id_data.indexOf(this.api_data[i].code)== -1 ){
                    console.log(this.api_data[i]);

                   let formData = new FormData();  
                   formData.append("name",this.api_data[i].code);  

                   formData.append("schedule",this.api_data[i].info.name);  
                   formData.append("timer",this.api_data[i].metadata[4].value==null?"":this.api_data[i].metadata[4].value);  
                   formData.append("node", JSON.stringify( this.api_data[i] ) );  

                   formData.append("orderby",i); 
                   formData.append("status",1);  

                   formData.append("schedule_en",this.api_data[i].info.name);  
                   formData.append("timer_en",this.api_data[i].metadata[4].value==null?"":this.api_data[i].metadata[4].value);  
                   formData.append("node_en",JSON.stringify( this.api_data[i] ) );  
                   

                   let t= axios
                      .post(window.my_api + "api/bus/create-or-update-bus", formData, {
                        headers: window.my_header,
                      })
                      .then((res) => {

                        if (res.status == 200) {
                            
                            this.api_counter=this.api_counter+1;

                        }
                       
                      });
                    array_process.push(t);


                  

                } 
            }

            if(array_process.length>0){
                Promise.all(array_process).then(()=>{
                   this.is_api=false;
                });
            }else{
                this.is_api=false;
            }

            
            

        });

    },
    RunDelete(){
      if(confirm("Bạn muốn xoá tất cả dữ liệu tuyến xe Bus?")){
            let id_data=[];
            for (var i = this.main.length - 1; i >= 0; i--) {
                id_data.push(this.main[i].id) ;
            }

              axios
                  .delete(window.my_api + "api/bus/remove-bus", {
                    data: { busIds: id_data },
                    headers: window.my_header
                  })
                  
                  .then((res) => {
                    if (res.status == 200) {
                      alert("Xoá thành công.");

                     
                    } else {
                      alert(
                        "Hệ thống không hoạt động, vui lòng liên hệ với Admin."
                      );
                    }
                   
                  });

        }
    }
  },
};
</script>








